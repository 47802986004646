<script setup>
</script>

<template>
    <div class="nav-padding container-fluid">
        <div class="py-3">
            <span class="fs-3">About</span>
            <p>
                Trained as a software developer, I became more and more interested in the visual poetry of photography when I moved into developing multimedia applications. During this period my proximity to designers and artists helped me develop a passion for photography. I started taking photos with a small point-and-shoot digital camera and for several years I practiced photography as a hobby.

                At this point of my life, I would like to become more serious about photography and make it my profession. I am interested primarily in portrait photography but I also enjoy finding patterns and shapes in the world around me.

                This website is a showcase of my work and hopefully a place of communication with other photographers and possibly people who would seek my services.
            </p>
        </div>
        <div class="py-3">
            <span class="fs-3">Services</span>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
                felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede
                justo, fringilla vel, aliquet nec, vulputate
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam
                felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede
                justo, fringilla vel, aliquet nec, vulputate
            </p>
        </div>
        <div class="py-3">
            <ul class="list-unstyled">
                <li><i class="fa-brands fa-instagram"></i> <a class="text-black" href="https://www.instagram.com/kazoompa/">www.instagram.com/kazoompa</a></li>
                <li><i class="fa-regular fa-envelope"></i> <a class="text-black" href="mailto:ramin@kazoompa.com">ramin@kazoompa.com</a></li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
    .nav-padding {
        padding-bottom: 10rem;
    }
</style>