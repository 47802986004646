<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import Thumbnails from '../components/Thumbnails.vue';
import Photos from '../components/Photos.vue';
import Nav from '../components/Nav.vue';

const windowSize = ref(null);

const updateWindowSize = () => {
    windowSize.value = window.innerWidth || document.documentElement.clientWidth;
};

onMounted(() => {
    updateWindowSize();
    window.addEventListener('resize', updateWindowSize);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateWindowSize);
});

</script>

<template>
    <div :class="{'container-fluid': windowSize <= 992}">
        <Nav></Nav>
        <Thumbnails class="pt-2"></Thumbnails>
        <Photos></Photos>
    </div>
</template>

<style scoped></style>
