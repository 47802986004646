<script setup>
import { onMounted, onUnmounted, watch, ref, nextTick } from 'vue';

const props = defineProps({
    url: {
        type: String,
    },
});

const photo = ref(null);
const overlay = ref(null);

const unwatch = watch(
    () => props.url,
    (url) => {
        if (url) {
            onShowPhoto(url);
        } else {
            onHidePhoto();
        }
    }
);

function onShowPhoto(url) {
    Velocity(
        photo.value,
        {
            opacity: [1, 0],
            blur: 8,
        },
        {
            easing: 'easeIn',
            duration: 350,
            begin: () => {
                photo.value.style.zIndex = 9999;
                photo.value.parentElement.style.display = 'flex';
                photo.value.style.blur = 'blur(8px)';
                photo.value.style.opacity = 0;
                photo.value.src = url;
            },
            complete: () => {
                Velocity(
                    photo.value,
                    {
                        blur: [0, 8],
                    },
                    {
                        easing: 'easeOut',
                        duration: 50,
                    }
                );
            },
        }
    );
}

function onHidePhoto(url) {
    Velocity(
        photo.value,
        {
            opacity: [0, 1],
        },
        {
            easing: 'easeOut',
            duration: 250,
            begin: () => {
                photo.value.style.opacity = 1;
            },
            complete: () => {
                photo.value.src = null;
                photo.value.parentElement.style.display = 'none';
            },
        }
    );
}

function darkenRGB(rgb, darknessFactor) {
    // Ensure darknessFactor is between 0 and 1
    darknessFactor = Math.min(1, Math.max(0, darknessFactor));

    // Extract RGB components
    var r = rgb.r;
    var g = rgb.g;
    var b = rgb.b;

    // Darken each component
    rgb.r = Math.round(r * (1 - darknessFactor));
    rgb.g = Math.round(g * (1 - darknessFactor));
    rgb.b = Math.round(b * (1 - darknessFactor));
}

function brightenRGB(rgb, brightnessFactor) {
    // Ensure brightnessFactor is between 0 and 1
    brightnessFactor = Math.min(1, Math.max(0, brightnessFactor));

    // Extract RGB components
    var r = rgb.r;
    var g = rgb.g;
    var b = rgb.b;

    // Brighten each component
    rgb.r = Math.round(r + (255 - r) * brightnessFactor);
    rgb.g = Math.round(g + (255 - g) * brightnessFactor);
    rgb.b = Math.round(b + (255 - b) * brightnessFactor);
}

function isColorBright(rgb) {
    // Convert RGB to relative luminance
    var r = rgb.r / 255;
    var g = rgb.g / 255;
    var b = rgb.b / 255;

    // Calculate relative luminance
    var luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Return true if luminance is greater than 0.5 (bright color), otherwise false
    return luminance > 0.5;
}

function getAverageRGB() {
    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data,
        width,
        height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = photo.value.naturalHeight || photo.value.offsetHeight || photo.value.height;
    width = canvas.width = photo.value.naturalWidth || photo.value.offsetWidth || photo.value.width;

    context.drawImage(photo.value, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        /* security error, img on diff domain */ alert('x');
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    canvas.remove();

    return rgb;
}

onMounted(async () => {
    await nextTick();
    photo.value.onload = () => {
        const rgb = getAverageRGB();
        if (isColorBright(rgb)) {
            darkenRGB(rgb, 0.9);
        } else {
            brightenRGB(rgb, 0.9);
        }

        overlay.value.style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9)`;
    };
});

onUnmounted(() => {
    unwatch();
});
</script>

<template>
    <div v-show="photo && photo.src">
        <div ref="overlay" class="overlay">
            <img ref="photo" class="" alt="Selected photo" />
            <button @click="$emit('close-photo')" class="btn btn-outline-secondary photo-close-button">Close</button>
        </div>
    </div>
</template>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo-close-button {
  position: fixed;
  top: 3rem;
  left: 3rem;
  z-index: 1000; /* Ensure the close button appears on top of the overlay */
}
</style>
