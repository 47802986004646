<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import AppDb from '../db/AppDb';
import Photo from './Photo.vue';
import EventBus from '../libs/events/EventBus';

const current = ref({ url: null, type: null });
const photo = ref(null);

function onClose() {
    if (current.value.url === null) return;

    document.addEventListener('keyup', onKeyup);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('click', onMouseClick);

    current.value.url = null;
    current.value.type = null;
    EventBus.emit('photo-closed');
}

function onShowPhoto(type, url) {
    document.addEventListener('keyup', onKeyup);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('click', onMouseClick);

    current.value.type = type;
    current.value.url = url;
}

const onKeyup = (event) => {
    switch (event.key) {
        case 'Escape':
            onClose();
            break;
        case 'ArrowLeft':
            current.value.url = AppDb.prev(current.value.type, current.value.url);
            break;
        case 'ArrowRight':
            current.value.url = AppDb.next(current.value.type, current.value.url);
            break;
    }
};

const photoUrl = computed(() => {
    return current.value.url ? `/assets/${current.value.type}/normal/${current.value.url}` : null;
});

const isLeft = (clientX) => {
    const mid = window.innerWidth / 2;
    return clientX < mid;
};

const isRight = (clientX) => {
    const mid = window.innerWidth / 2;
    return clientX > mid;
};

const onMouseMove = (event) => {
    if (isLeft(event.clientX)) {
        console.log('left');
        photo.value.$el.classList.remove('mouse-right');
        photo.value.$el.classList.add('mouse-left');
    } else if (isRight(event.clientX)) {
        console.log('right');
        photo.value.$el.classList.remove('mouse-left');
        photo.value.$el.classList.add('mouse-right');
    }
};

const onMouseClick = (event) => {
    if (isLeft(event.clientX)) {
        current.value.url = AppDb.prev(current.value.type, current.value.url);
    } else if (isRight(event.clientX)) {
        current.value.url = AppDb.next(current.value.type, current.value.url);
    }
};

onMounted(() => {
    EventBus.on('show-photo', onShowPhoto);
});

onUnmounted(() => {
    EventBus.clear('show-photo', onShowPhoto);
    // Be paranoid!
    document.removeEventListener('keyup', onKeyup);
    document.removeEventListener('click', onMouseClick);
    document.removeEventListener('mousemove', onMouseMove);
});
</script>

<template>
    <div>
        <Photo ref="photo" :url="photoUrl" @close-photo="onClose" />
    </div>
</template>

<style scoped>
.mouse-left {
    cursor: url('/assets/images/chevron-left.svg'), auto !important;
}
.mouse-right {
    cursor: url('/assets/images/chevron-right.svg'), auto !important;
}
</style>
