<script setup>
import { onMounted, onUnmounted, ref, nextTick } from 'vue';
import 'velocity-animate/velocity.ui'; // Import UI Pack
import AppDb from '../db/AppDb';
import Velocity from 'velocity-animate';
import Thumbnail from './Thumbnail.vue';
import EventBus from '../libs/events/EventBus';

const portraits = ref(AppDb.portraits);
const infos = ref([]);
const cols = ref(null);

const portraitRefs = ref([]);
let onMouseEnter = () => {};
let onMouseOut = () => {};
let onShowPhoto = () => {};
let loadedCount = 0;
let tweenCount = 0;
let ready = ref(false);
let touchTimeoutId = null;

const enableMouseEvents = () => {
    onMouseEnter = mouseEnter;
    onMouseOut = mouseOut;
    onShowPhoto = mouseClick;
};

const disableMouseEvents = () => {
    onMouseEnter = () => {};
    onMouseOut = () => {};
    onShowPhoto = () => {};
};

const onPhotoClosed = () => {
    enableMouseEvents();
};

const mouseEnter = (index) => {
    if (window.innerWidth <= 992) return;
    const image = portraitRefs.value[index];
    const info = infos.value[index];

    Velocity(image, 'stop');
    Velocity(info, 'stop');

    Velocity(
        image,
        {
            scale: [1.1, 1],
        },
        {
            duration: 250,
            easing: 'easeIn',
        }
    );
    Velocity(info, { opacity: 1, translateY: ['-10%', 0] }, { delay: 250, duration: 250, easing: 'easeIn' });
};

const mouseOut = (index) => {
    if (window.innerWidth <= 992) return;
    const image = portraitRefs.value[index];
    const info = infos.value[index];

    Velocity(image, 'stop');
    Velocity(info, 'stop');

    Velocity(
        image,
        {
            scale: [1, 1.1],
        },
        {
            duration: 250,
            easing: 'easeOut',
            complete: () => {
                image.style.transform = '';
            },
        }
    );
    Velocity(info, { opacity: 0, translateY: '-10%' }, { duration: 250, easing: 'easeOut' });
};

const onTouchStart = (index) => {
    touchTimeoutId = setTimeout(() => {
        const info = infos.value[index];
        Velocity(info, { opacity: 1, translateY: ['-10%', 0] }, { delay: 250, duration: 250, easing: 'easeIn' });
        clearTimeout(touchTimeoutId);
        touchTimeoutId = null;
    }, 750);
};

const onTouchEnd = (index) => {
    const info = infos.value[index];
    if (touchTimeoutId !== null) {
        clearTimeout(touchTimeoutId);
        touchTimeoutId = null;
    }
    Velocity(info, { opacity: 0, translateY: '-10%' }, { duration: 250, easing: 'easeOut' });
};

const mouseClick = (type, name, index) => {
    if (window.innerWidth <= 992) return;
    disableMouseEvents();

    portraitRefs.value.forEach((element) => {
        Velocity(element, 'stop');
        element.style.transform = '';
    });

    const image = portraitRefs.value[index];
    const info = infos.value[index];

    Velocity(
        image,
        {
            scale: 1,
        },
        {
            duration: 150,
            easing: 'easeOut',
            complete: () => (image.style.transform = ''),
        }
    );
    Velocity(
        info,
        {
            opacity: 0,
            translateY: '-10%',
        },
        {
            duration: 250,
            easing: 'easeIn',
            complete: () => EventBus.emit('show-photo', type, name),
        }
    );
};

const tweenCompleted = () => {
    tweenCount++;
    if (tweenCount === portraits.value.length) {
        enableMouseEvents();
    }
};

const onTagsSelected = async (tags) => {
    disableMouseEvents();
    tweenCount = 0;
    loadedCount = 0;

    portraits.value =
        tags.length > 0
            ? AppDb.portraits.filter((portrait) => tags.some((tag) => portrait.tags.includes(tag)))
            : AppDb.portraits;
    cols.value = portraits.value.length % 2 === 0 ? 'col-md-12 col-lg-3' : 'col-md-12 col-lg-4';
    await nextTick();
    showPortraits();
};

const showPortraits = () => {
    tweenCount = 0;

    portraitRefs.value.forEach((element) => {
        Velocity(element, 'stop');
        element.style.opacity = 0;
    });

    portraitRefs.value.forEach((element, index) => {
        Velocity(
            element,
            {
                opacity: [1, 0],
            },
            {
                begin: () => {
                    element.style.opacity = 0;
                },
                delay: index * 60,
                easing: 'easeInOut',
                duration: 350,
                complete: () => tweenCompleted(),
            }
        );
    });
};

const onThumbnailLoaded = () => {
    if (ready.value) return;
    loadedCount++;
    ready.value = loadedCount === portraits.value.length;
    if (ready.value) {
        cols.value = portraits.value.length % 2 === 0 ? 'col-md-12 col-lg-3' : 'col-md-12 col-lg-4';
        EventBus.emit('thumbnails-loaded');
        showPortraits();
    }
};

onMounted(async () => {
    EventBus.on('photo-closed', onPhotoClosed);
    EventBus.on('tags-selected', onTagsSelected);
    EventBus.on('nav-opened', disableMouseEvents);
    EventBus.on('nav-closed', enableMouseEvents);
});

onUnmounted(() => {
    EventBus.clear('photo-closed', onPhotoClosed);
    EventBus.clear('tags-selected', onTagsSelected);
    EventBus.clear('nav-opened', disableMouseEvents);
    EventBus.clear('nav-closed', enableMouseEvents);
});
</script>

<template>
    <div class="padding-thumbnails app-x-padding text-center">
        <div>
            <img v-show="!ready" class="wait-image" src="/assets/images/Wait.svg" />
            <div v-show="ready" class="row">
                <div
                    ref="portraitRefs"
                    :style="(opacity = 0)"
                    :class="cols"
                    v-for="(portrait, index) in portraits"
                    :key="index"
                >
                    <div class="position-relative d-inline-block">
                        <Thumbnail
                            class="my-2"
                            :url="`/assets/portraits/normal/${portrait.name}`"
                            :name="portrait.name"
                            @click.prevent="onShowPhoto('portraits', `${portrait.name}`, index)"
                            @loaded="onThumbnailLoaded()"
                            @mouseenter="onMouseEnter(index)"
                            @mouseout="onMouseOut(index)"
                            @touchstart="onTouchStart(index)"
                            @touchend="onTouchEnd(index)"
                        />
                        <span ref="infos" style="opacity: 0" class="thumbnail-info">{{ portrait.info }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media (min-width: 992px) {
    .padding-thumbnails {
        padding-top: 0 !important;
    }

    .thumbnail-info {
        bottom: 1rem;
    }
}
@media (max-width: 991px) {
    .thumbnail-info {
        bottom: 0rem;
    }
}

.thumbnail-info {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    line-height: 3;
    text-align: center;
    pointer-events: none;
    padding-bottom: 0.5rem;
}

.wait-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
