<script setup>
import { ref } from 'vue';

defineProps({
    url: {
        type: String,
        require: true,
    },
    name: {
        type: String,
    },
});

const image = ref(null);
</script>
<template>
    <div>
        <!-- <img ref="image" :src="url" class="thumbnail" :title="name" :alt="name ? name : url" @load="$emit('loaded')" /> -->
        <img ref="image" :src="url" class="thumbnail" :alt="name ? name : url" @load="$emit('loaded')" />
    </div>
</template>
<style scoped>
img {
    border-radius: 5px;
    cursor: pointer;
}

@media (min-width: 992px) {
    .thumbnail {
        padding: 1rem;
        background-color: white;
        /* border: var(--bs-border-width) solid var(--bs-border-color); */
        /* border-radius: var(--bs-border-radius); */
        max-width: 100%;
        height: auto;
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
    }
}

@media (max-width: 992px) {
    .thumbnail {
        padding: 0.2rem;
        background-color: white;
        /* border: var(--bs-border-width) solid var(--bs-border-color); */
        /* border-radius: var(--bs-border-radius); */
        max-width: 100%;
        height: auto;
    }
}
</style>
