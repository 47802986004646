<script setup>
import { reactive, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import AppDb from './db/AppDb';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap';

const status = reactive({ value: 0 });
onMounted(async () => {
    await fetch(`/assets/data/photos.json`)
        .then((response) => response.json())
        .then((json) => {
            AppDb.init(json);
            status.value = 1;
        })
        .catch((error) => {
            console.error(error);
            status.value = -1;
        });

    document.addEventListener('contextmenu', function (event) {
        event.preventDefault();
    });
});
</script>

<template>
    <div class="pt-0" v-cloak>
        <RouterView v-if="status.value > 0" />
        <div v-if="status.value < 0" class="alert alert-danger">The config file is missing</div>
    </div>
</template>

<style scoped></style>
