class EventBus {
    constructor() {
        this.__listeners = {};
    }

    clear(name, handler) {
        const listeners = this.__listeners[name];
        if (listeners) {
            listeners.delete(handler);
        }
    }

    on(name, handler) {
        let listeners = this.__listeners[name];
        if (!listeners) {
            listeners = new Set();
            this.__listeners[name] = listeners;
        }

        listeners.add(handler);
    }

    emit(name, ...payload) {
        console.debug('EventBus >>> ', name, this.__listeners[name]);

        (this.__listeners[name] || []).forEach((handler) => {
            handler(...payload);
        });
    }

    stat() {
        console.debug('EventBus Stat ');
        Object.keys(this.__listeners).forEach((key) =>
            console.debug(`\tHandler: ${key} => ${this.__listeners[key] ? this.__listeners[key].size : 0}`),
        );
    }
}

export default new EventBus();
