<script setup>
import { ref } from 'vue';
import AppDb from '../db/AppDb';

const emit = defineEmits(['tags-selected']);
const tags = ref(AppDb.tags.map((tag) => ({selected: false, name: tag})));


const onToggleState = (event, index) => {
    event.stopPropagation();
    tags.value[index].selected = !tags.value[index].selected;
    emit('tags-selected', tags.value.filter((state) => state.selected === true).map((state) => state.name));
    return false;
};

</script>

<template>
    <div class="nav-padding container-fluid py-1">
        <span>Filter with tags: </span>
        <span class="form-group ms-2">
            <template v-for="(tag, index) in tags" :key="index">
                <button type="button" class="btn btn-sm" :class="{'ms-1': index > 0, 'btn-info': tag.selected, 'btn-secondary': !tag.selected}" @click.prevent="onToggleState($event, index)">
                    <i class="fa-solid fa-hashtag"></i>
                    {{ tag.name }}
                </button>
            </template>
        </span>
    </div>
</template>
