class AppDb {
    constructor() {
        this.__data = null;
    }

    init(data) {
        this.__data = data || {};

    }

    ready() {
        return this.__data !== null;
    }

    prev(type, item) {
        const index = (this.__data[type] || []).findIndex((d) => d.name === item);
        return index === -1 ? null : this.__data[type][(index + this.__data[type].length - 1) % this.__data[type].length].name;
    }

    next(type, item) {
        const index = (this.__data[type] || []).findIndex((d) => d.name === item)
        return index === -1 ? null :  this.__data[type][(index + 1) % this.__data[type].length].name;
    }

    get tags() {
        return this.__data.tags || [];
    }

    get portraits() {
        return this.__data.portraits || [];
    }
}

export default new AppDb();
